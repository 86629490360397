// Dependencies
import React, { useState, useEffect, useRef } from "react"
// Components
import Layout from "../components/Layout"

const EXCELSIOR = [
  [4],
  [3],
  [2,6],
  [4,7],
  [0,3,4,5,8],
  [1,4],
  [2,6],
  [5],
  [4]
];

const ORSINI = [
  [0,4,7,8],
  [0,4],
  [2,6],
  [3,5],
  [0,1,4,7,8],
  [3,5],
  [2,6],
  [4,8],
  [0,1,4,8]
];

const GORST = [
  [0,1,4,7,8],
  [0,3,5,8],
  [2,6],
  [],
  [0,2,4,6,8],
  [],
  [2,6],
  [0,3,5,8],
  [0,1,4,7,8]
];

const STANDARD = [
  [0,8],
  [1,7],
  [2,4,6],
  [3,5],
  [2,3,4,5,6],
  [3,5],
  [2,4,6],
  [1,7],
  [0,8]
];

const NORTH_ACTON = [
  [4],
  [3,5],
  [2,6],
  [0,8],
  [1,4,7],
  [0,8],
  [2,6],
  [3,5],
  [4]
];

const North = () => (
  <div className="north">
    <svg width="14" height="38" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 8.544H1.224V2.952L7.032 9.036V0.539999H5.808V6.084L0 0V8.544ZM6.23492 14.2772L4.07799 18.2265C3.94563 18.4689 4.0348 18.7727 4.27716 18.905C4.51951 19.0374 4.82328 18.9482 4.95564 18.7059L6.53398 15.8159L12.8495 37.3308L13.809 37.0491L7.49349 15.5342L10.3835 17.1126C10.6258 17.2449 10.9296 17.1558 11.062 16.9134C11.1943 16.671 11.1051 16.3673 10.8628 16.2349L6.9134 14.078C6.67105 13.9456 6.36728 14.0348 6.23492 14.2772Z" fill="black"/>
    </svg>
  </div>
)

const Icon = ({
  id,
  matrix = [[4], [3], [2, 6], [4, 7], [0, 3, 4, 5, 8], [1, 4], [2, 6], [5], [4]],
  fill = "#000000"
}) => {

  const scale = 2;

  return (
    <svg width={9 * scale} height={9 * scale} viewBox={`0 0 ${9 * scale} ${9 * scale}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      {matrix.map((cells, row) => {
        return (
          cells.map((cell, column) => {
            return (
              <rect x={cell * scale} y={row * scale} width={scale} height={scale} fill={fill} key={`${id}-x${cell}-y${row}`} />
            )
          })
        )
      })}
    </svg>
  )
}

const Place = ({ name, href, matrix, top = "50%", left = "50%", handlers }) => {

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className="place" style={{ top, left }} {...handlers}>
      <Icon matrix={matrix} />
      <div className="label">{name}</div>
    </a>
  )
}

const Info = () => {

  return (
    <div className="info">
      <span>Join The Republic. </span>
      <span className="copy">
        E-mail us at <br />
        <a href="mailto:hello@therepublicofparkroyal.com" target="_blank" rel="noreferrer">hello@therepublicofparkroyal.com</a> <br />
        or follow <a href="https://instagram.com/therepublicofparkroyal" target="_blank" rel="noopener noreferrer">@therepublicofparkroyal</a>.
      </span>
    </div>
  )
}

const HoldingPage = () => {

  const timer = useRef();
  const [reveal, setReveal] = useState(false)
  const [showName, setShowName] = useState(false)

  useEffect(() => {
    setReveal(true)
    setTimeout(() => {
      setShowName(true)
    }, 5000)
  }, [])

  const handleEnter = () => {
    clearTimeout(timer.current)
    setShowName(false)
  }

  const handleLeave = () => {
    timer.current = setTimeout(() => {
      setShowName(true)
    }, 2000)
  }

  const handlers = {
    onMouseEnter: handleEnter,
    onMouseLeave: handleLeave,
  }

  return (
    <Layout>
      <Info />
      <North />
      <div onTouchStart={handleEnter} onTouchEnd={handleLeave}>
        <div className="trpr" style={{
          opacity: showName ? 1 : 0,
          transition: '1s'
        }}>
          <h1>The Republic of Park Royal</h1>
        </div>
        <div className="map__wrapper" >
          <div className={`map ${reveal ? 'reveal' : ''} ${showName ? 'show-name' : 'hide-name'}`}>
            <Place id="orsini" href="https://www.orsinistudios.com/" top="71%" left="5%" name="Orsini Studios" matrix={ORSINI} handlers={handlers} />
            {/* <Place id="gorst" href="https://www.instagram.com/42.gorst/" top="71%" left="16%" name="42 Gorst" matrix={GORST} handlers={handlers} /> */}
            <Place id="excelsior" href="https://www.excelsior.london/" top="84%" left="32%" name="Excelsior Studios" matrix={EXCELSIOR} handlers={handlers} />
            <Place id="standard" href="https://www.standardstudios.ltd/" top="24%" left="76%" name="Standard Studios" matrix={STANDARD} handlers={handlers} />
            {/* <Place id="north-action" href="https://www.instagram.com/contained.at.nar/" top="10%" left="71%" name="Contained @ NAR" matrix={NORTH_ACTON} handlers={handlers} /> */}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HoldingPage
